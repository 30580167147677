import * as React from 'react';

import type { HeadFC } from 'gatsby';

import { SEO } from '../components/SEO';
import { ContentWrapper } from '../components/ContentWrapper';
import { ExternalLink } from '../components/ExternalLink';
import { Layout } from '../components/Layout';

const LicensePage = () => {
  return (
    <Layout>
      <section>
        <ContentWrapper>
          <div className="max-w-xl mt-8 mb-40 prose dark:prose-invert">
            <h1 className="text-4xl mb-10">License</h1>
            <p>
              Everything needs some sort of license. Likewise everything in this
              site that I have written/coded or developed in anyway is licensed
              under the:
            </p>
            <p>
              <u className="accent-underlining">
                Creative Commons Attribution 4.0 Unported License.
              </u>
            </p>
            <p>
              This gives freedom to my work, you can use it personally or
              commercially. You just need to give attribution to my self. You
              can find out more about the creative commons at there{' '}
              <a href="https://creativecommons.org">home page</a>.
            </p>
            <p>
              The Creative Commons Attribution 4.0 Unported License is also an
              approved{' '}
              <ExternalLink href="https://freedomdefined.org/Licenses/CC-BY">
                Free Cultural Works licence
              </ExternalLink>
              <ExternalLink
                rel="license"
                href="https://creativecommons.org/licenses/by/3.0/deed.en_US"
              >
                <img
                  alt="Creative Commons License"
                  src="https://i.creativecommons.org/l/by/3.0/88x31.png"
                />
              </ExternalLink>
              These works by{' '}
              <ExternalLink
                href="https://brendan.horan.hk"
                property="cc:attributionName"
                rel="cc:attributionURL"
              >
                Brendan Horan
              </ExternalLink>{' '}
              are licensed under a{' '}
              <ExternalLink
                rel="license"
                href="https://creativecommons.org/licenses/by/3.0/deed.en_US"
              >
                Creative Commons Attribution 3.0 Unported License
              </ExternalLink>
              .
            </p>
            <p>
              Based on a work at{' '}
              <ExternalLink href="https://brendan.horan.hk" rel="dct:source">
                https://brendan.horan.hk
              </ExternalLink>
              .
            </p>
          </div>
        </ContentWrapper>
      </section>
    </Layout>
  );
};

export default LicensePage;

export const Head: HeadFC = () => (
  <SEO title="License - Brendan Horan" noIndex />
);
